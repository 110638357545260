import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/step-1",
    name: "Step1",
    component: () => import("@/views/Step1"),
  },
  {
    path: "/step-2",
    name: "Step2",
    component: () => import("@/views/Step2"),
  },
  {
    path: "/step-3",
    name: "Step3",
    component: () => import("@/views/Step3"),
  },
  {
    path: "/step-4",
    name: "Step4",
    component: () => import("@/views/Step4"),
  },
  {
    path: "/step-5",
    name: "Step5",
    component: () => import("@/views/Step5"),
  },
  {
    path: "/finish",
    name: "Finish",
    component: () => import("@/views/FinishView"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
