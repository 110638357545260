import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    /* Static Data */
    accommodation_answers: [
      "İstiyorum",
      "İstemiyorum, dış katılımcıyım.",
      "İstemiyorum, bildiri ile katılan dış katılımcıyım.",
      "Konaklama olmadan devam et.",
    ],
    room_type_answers: ["Single", "Double"],
    companion_answers: ["Evet", "Hayır"],
    transfer_answers: ["İstiyorum", "İstemiyorum"],
    transfer_directions: ["Tek yön", "Çift yön"],
    transfer_types: ["Shuttle", "Özel transfer"],
    billing_types: ["Şahıs adına", "Kurum adına"],
    payment_types: ["Kredi/Banka kartı", "Banka transferi"],
    cc_months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    cc_years: [
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
    ],
    /* Other */
    registration_types: {},
    active_step: 0,
    informations: {
      personal: {
        full_name: "",
        company: "",
        phone: "",
        email: "",
        registration_type: null,
        price: "",
      },
      accommodation: {
        do_you_want: null,
        room_type: null,
        companion_question: null,
        companion_full_name: "",
        companion_phone: "",
        check_in: new Date("2023-11-20"),
        check_out: new Date("2023-11-23"),
        price: "",
      },
      transfer: {
        do_you_want: null,
        direction: null,
        type: null,
      },
      billing: {
        type: null,
        first_name: "",
        last_name: "",
        tc_number: "",
        company_name: "",
        tax_office: "",
        tax_no: "",
        phone: "",
        email: "",
        address: "",
      },
      payment: {
        type: null,
        total_fee: "",
        cc_full_name: "",
        cc_number: "",
        cc_month: null,
        cc_year: null,
        cc_cvv: "",
        installment: null,
      },
    },
    card_installments: [],
  },
  getters: {
    _informations(state) {
      return state.informations;
    },
    _registration_types(state) {
      return state.registration_types;
    },
    _accommodation_answers(state) {
      return state.accommodation_answers;
    },
    _room_type_answers(state) {
      return state.room_type_answers;
    },
    _companion_answers(state) {
      return state.companion_answers;
    },
    _transfer_answers(state) {
      return state.transfer_answers;
    },
    _transfer_directions(state) {
      return state.transfer_directions;
    },
    _transfer_types(state) {
      return state.transfer_types;
    },
    _billing_types(state) {
      return state.billing_types;
    },
    _payment_types(state) {
      return state.payment_types;
    },
    _cc_months(state) {
      return state.cc_months;
    },
    _cc_years(state) {
      return state.cc_years;
    },
    _active_step(state) {
      return state.active_step;
    },
    _personal(state) {
      return state.informations.personal;
    },
    _accommodation(state) {
      return state.informations.accommodation;
    },
    _transfer(state) {
      return state.informations.transfer;
    },
    _billing(state) {
      return state.informations.billing;
    },
    _payment(state) {
      return state.informations.payment;
    },
    _card_installments(state) {
      return state.card_installments;
    },
  },
  mutations: {
    setRegistrationTypes(state, payload) {
      state.registration_types = payload;
    },
    setActiveStep(state, payload) {
      state.active_step = payload;
    },
    setPersonelInformations(state, payload) {
      state.informations.personal = payload;
    },
    setAccommodationPrice(state, payload) {
      state.informations.accommodation.price = payload;
    },
    setCardInstallments(state, payload) {
      state.card_installments = payload;
    },
  },
  actions: {
    GET_REGISTRATION_TYPES(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/registration-types`)
          .then((res) => {
            context.commit("setRegistrationTypes", res.data.registration_types);
            return resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    STEP_1(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/step-1`, context.getters._informations)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    STEP_2(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/step-2`, context.getters._informations)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    STEP_3(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/step-3`, context.getters._informations)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    ACCOMMODATION(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/accommodation`, context.getters._accommodation)
          .then((res) => {
            context.commit("setAccommodationPrice", res.data?.price || 0);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    COMPLETE_FORM(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/register`, context.getters._informations)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    CARD_BIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cardbin`, { bin_number: payload.cardbin })
          .then((res) => {
            if (res.data.card_info?.CommissionPackages)
              context.commit(
                "setCardInstallments",
                res.data.card_info?.CommissionPackages
              );
            else context.commit("setCardInstallments", []);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setCardInstallments", []);
            reject(err?.response?.data || err);
          });
      });
    },
  },
  modules: {},
});
