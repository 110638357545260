import { createApp } from "vue";
import App from "./FormApp";
import router from "./router";
import store from "./store";

/* Axios */
import axios from "axios";

/* Bootstrap */
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";

/* Bootstrap Icons */
import "bootstrap-icons/font/bootstrap-icons.css";

/* Sweetalert 2 */
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/* Animate Css */
import "animate.css";

/* Vue Date Picker */
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

axios.defaults.baseURL =
  process.env.VUE_APP_BASE || "http://localhost:8000/api";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.component("VueDatePicker", VueDatePicker);
app.mount("#app");
