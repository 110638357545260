<template>
  <div class="intro">
    <ul class="list-group">
      <li class="list-group-item">
        <i class="bi bi-info-circle me-1"></i>
        Bu form 5 adımdan oluşmaktadır, kaydınızın başarıyla tamamlanması için
        bütün adımları eksiksiz tamamlamalısınız.
      </li>
      <li class="list-group-item">
        <i class="bi bi-info-circle me-1"></i>
        Eksik bilgilerinizi ilgili adıma geri dönerek tamamlayabilirsiniz.
      </li>
      <!-- <li class="list-group-item">
        <i class="bi bi-info-circle me-1"></i>
        Formu tamamladığınızda tarafınıza bir konfirmasyon maili iletilecektir.
      </li> -->
    </ul>
    <div class="d-flex align-items-center flex-column">
      <div class="d-flex align-items-center w-100">
        <hr class="flex-fill bordered me-3" />
        <button
          class="btn mt-5 btn-success mb-5 animate__animated animate__infinite animate__pulse"
          @click="startForm"
        >
          Forma başla!
        </button>
        <hr class="flex-fill bordered ms-3" />
      </div>
      <img src="@/assets/intro.svg" alt="intro-image" width="380" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  methods: {
    startForm() {
      this.$store.commit("setActiveStep", 1);
      this.$router.push({ name: "Step1" });
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex .bordered {
  opacity: 0.75 !important;
  border-color: #2e2281;
  border-width: 2px;
}
</style>
