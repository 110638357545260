<template>
  <main class="form-app">
    <div class="container">
      <div class="row">
        <div class="mx-auto">
          <h3 class="text-center mt-3">
            <strong class="text-primary">KBUD 2023</strong>
            Online Kayıt - Konaklama Formu
          </h3>
          <ul class="nav nav-pills fw-bold py-4 d-flex">
            <li class="nav-item flex-fill px-2 mb-3">
              <button
                class="w-100 nav-link d-flex flex-column align-items-center"
                :class="{ active: $route.name === 'Step1' }"
                @click="setStep(1)"
                :disabled="_active_step < 1"
              >
                <strong><i class="bi bi-1-circle fs-5 mb-1"></i></strong>
                <span class="small">
                  <i class="bi bi-person me-1"></i>
                  Kişisel Bilgiler
                </span>
              </button>
            </li>
            <li class="nav-item flex-fill px-2 mb-3">
              <button
                class="w-100 nav-link d-flex flex-column align-items-center"
                :class="{ active: $route.name === 'Step2' }"
                @click="setStep(2)"
                :disabled="_active_step < 2"
              >
                <strong><i class="bi bi-2-circle fs-5 mb-1"></i></strong>
                <span class="small">
                  <i class="bi bi-house-door me-1"></i>
                  Konaklama Bilgileri
                </span>
              </button>
            </li>
            <!-- <li class="nav-item flex-fill px-2">
              <button
                class="w-100 nav-link d-flex flex-column align-items-center"
                :class="{ active: $route.name === 'Step3' }"
                @click="setStep(3)"
                :disabled="_active_step < 3"
              >
                <strong><i class="bi bi-3-circle fs-5 mb-1"></i></strong>
                <span class="small">
                  <i class="bi bi-airplane me-1"></i>
                  Transfer
                </span>
              </button>
            </li> -->
            <li class="nav-item flex-fill px-2 mb-3">
              <button
                class="w-100 nav-link d-flex flex-column align-items-center"
                :class="{ active: $route.name === 'Step4' }"
                @click="setStep(4)"
                :disabled="_active_step < 4"
              >
                <strong><i class="bi bi-3-circle fs-5 mb-1"></i></strong>
                <span class="small">
                  <i class="bi bi-file-earmark-text me-1"></i>
                  Fatura Bilgileri
                </span>
              </button>
            </li>
            <li class="nav-item flex-fill px-2 mb-3">
              <button
                class="w-100 nav-link d-flex flex-column align-items-center"
                :class="{ active: $route.name === 'Step5' }"
                @click="setStep(5)"
                :disabled="_active_step < 5"
              >
                <strong><i class="bi bi-4-circle fs-5 mb-1"></i></strong>
                <span class="small">
                  <i class="bi bi-credit-card me-1"></i>
                  Ödeme
                </span>
              </button>
            </li>
          </ul>
          <div class="router">
            <router-view v-slot="{ Component }">
              <transition name="router" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FormApp",
  computed: {
    ...mapGetters(["_active_step"]),
  },
  methods: {
    setStep(step) {
      this.$router.push({ name: `Step${step}` });
    },
  },
  mounted() {
    this.$router.afterEach(() => {
      if (this.$route.query?.error) {
        this.$swal({
          icon: "error",
          title: "Ödeme sorunu",
          text: this.$route.query.error,
          confirmButtonText: "Tamam",
        });
        this.$router.push({ name: "Home" });
      }
    });
  },
};
</script>

<style lang="scss">
.router-enter-from {
  opacity: 0;
  transform: translateY(-2vh);
}

.router-leave-from,
.router-enter-to {
  opacity: 1;
  transform: translateY(0px);
}
.router-leave-to {
  opacity: 0;
  transform: translateY(2vh);
}
.router-enter-active,
.router-leave-active {
  transition: all 250ms ease-in-out;
}

.fade-enter-from {
  opacity: 0;
  transform: skewX(25deg) scale(1.05);
  max-height: 80px !important;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: skewX(0deg) scale(1);
}
.fade-leave-to {
  opacity: 0;
  transform: skewX(-25deg) scale(0.95);
  max-height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 250ms ease-in-out;
}

body {
  background: #f8f8f8 !important;
}
[readonly] {
  cursor: default;
  background: #f8f8f8 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav-item .nav-link {
  background: #27c2e9;
  color: white;
  border: 1px solid #d8d8d8 !important;
  &:disabled {
    background: #e8e8e8 !important;
    &:hover {
      color: #2e2281 !important;
    }
  }
  &.active {
    background: #2e2281 !important;
  }
  &:hover,
  &:focus {
    color: white !important;
  }
}
.text-primary {
  color: #2e2281;
}

.step-section {
  position: relative;
  margin-top: 1.5rem;
  border: 2px solid #2e2281;
  border-radius: 0.5rem;
  background: white;
  padding: 2.5rem 1.5rem 1.5rem;
  &-header {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    padding: 0.5rem 1rem;
    border-radius: 0.35rem;
    background: white;
    border: 2px solid #2e2281;
  }
  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    .btn {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  fieldset.field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 100px;
    margin: 1rem 0;
    .form-label {
      font-size: 0.9rem;
      font-weight: 500;
      background: #2e2281;
      color: white;
      border-radius: 0.35rem;
      width: 230px;
      min-width: 200px;
      margin-right: 0.75rem;
      margin-bottom: 0;
      text-align: center;
      padding: 0.5rem 0.75rem;
    }
  }
}
</style>
